<template>
  <div>
    <q-card
      v-for="item in data"
      :key="item.id"
      class="q-mb-sm border-bottom"
    >
      <product-object
        :data="item"
        is-item
      />
    </q-card>
  </div>
</template>

<script>
export default {
  name: 'StorageItemsCollection',
  props: {
    rawData: {
      type: Object,
      default () {
        return {}
      }
    },
    data: {
      type: Array,
      default () {
        return []
      }
    }
  }
}
</script>
